/* eslint-disable indent */
/* eslint-disable no-tabs */
import { useMemo } from "react";

// import c from "classnames";
import { GetStaticProps } from "next";
// import Image from "next/image";

import { Layout } from "@/components/Layout";
import { PageData, TableSectionProps } from "@/types/page";
import { makeAboutPageSchema, makeOrgSchema } from "@/helpers/schema";
import { getAllLandingPages, getLandingPageQuery } from "@/API/queries";
import { Hero } from "@/components/pageSections/Hero";
import { _Head } from "@/components/_head/_head";
import { ImageAndText } from "@/components/pageSections/ImageAndText";
import { BenefitsBar } from "@/components/pageSections/BenefitsBar";
import { DietComparisonContent } from "@/components/pageSections/DietComparison";
import { ReviewsSection } from "@/components/pageSections/ReviewsSection";
import { Quotes } from "@/components/pageSections/Quotes";
import { FullWidth } from "@/components/pageSections/FullWidth";
import { SuccessStories } from "@/components/pageSections/SuccessStories";
import { Timeline } from "@/components/pageSections/Timeline";
import { initializeApollo } from "@/API/apolloClient";
import { queryLoop } from "@/API/datoQueryLoop";
import { Products } from "@/components/pageSections/Products";
import { Table } from "@/components/pageSections/Table";
import { Extras } from "@/components/pageSections/Extras";
import { PriceEstimate } from "@/components/pageSections/PriceEstimate";
import { SocialVideos } from "@/components/pageSections/SocialVideos/SocialVideos";

export async function getStaticPaths () {
	const posts = await queryLoop(
		getAllLandingPages,
		"allNewLandingPages",
		"_allNewLandingPagesMeta"
	);

	const paths = posts
		.filter((item) => item.slug)
		.map((item) => {
			return {
				params: {
					id: item.slug,
				},
			};
		});

	return {
		paths,
		fallback: false,
	};
}

export const getStaticProps: GetStaticProps = async ({ params, preview }) => {
	try {
		const apolloClient = initializeApollo();
		const { data } = await apolloClient.query({
			query: getLandingPageQuery(
				!!preview ||
					process.env.NEXT_PUBLIC_URL === "https://localhost:3000" ||
					process.env.NEXT_PUBLIC_URL === "https://staging.purepetfood.com"
			),
			variables: { slug: params?.id },
			context: { clientName: preview === true
? "datoPreview"
: "dato" },
		});

		if (!data.allNewLandingPages || data.allNewLandingPages.length < 1) {
			return {
				notFound: true,
			};
		}

		return {
			props: {
				pageData: data.allNewLandingPages[0],
				id: params?.id,
			},
		};
	} catch (err) {
		console.log(err);
		
return { notFound: true };
	}
};

const Page = ({ pageData, id }: { pageData: PageData; id: string }) => {
	const pageDataSections = pageData.sections.map((section, i) => {
		switch (section.__typename) {
			case "LandingHeroRecord":
				return <Hero content={section} key={i} />;
			case "LandingImageTextRecord":
				return <ImageAndText content={section} key={i} />;
			case "LandingBenefitsBarRecord":
				return <BenefitsBar content={section} key={i} />;
			case "LandingDietComparisonRecord":
				return (
					<DietComparisonContent id={section.id} content={section} key={i} />
				);
			case "LandingReviewRecord":
				return <ReviewsSection hasPet={false} content={section} key={i} />;
			case "LandingQuoteRecord":
				return <Quotes content={section} key={i} />;
			case "LandingProductRecord":
				return <Products content={section} key={i} />;
			case "LandingExtraRecord":
				return <Extras content={section} key={i} />;
			case "LandingSuccessStoriesContentRecord":
				return <SuccessStories content={section} key={i} />;
			case "LandingFullWidthRecord":
				return <FullWidth content={section} key={i} />;
			case "LandingTimelineRecord":
				return <Timeline content={section} key={i} />;
			case "LandingPriceEstimateRecord":
				return <PriceEstimate content={section} key={section.id} />;
			case "LandingTableRecord":
				return (
					<Table content={section as unknown as TableSectionProps} key={i} />
				);
			case "LandingChooseTableRecord":
				return <Table content={section.table.table[0]} key={i} />;

			case "LandingSocialVideoRecord":
				return <SocialVideos {...section} />;
			default:
				return <></>;
		}
	}, []);

	const pagesToExclude = [
		"recipes",
		"reviews",
		"benefits",
		"pet-panel",
		"puppies",
	];

	const landingPageSchema =
		id === "about"
			? makeAboutPageSchema(pageData.metadata[0], id)
			: pagesToExclude.includes(id)
			? false
			: makeOrgSchema;

	const landingPageString = useMemo(
		() => (landingPageSchema
? JSON.stringify(landingPageSchema)
: ""),
		[landingPageSchema]
	);

	return (
		<Layout voucher={pageData.autoAppliedVoucher?.voucher} hasFooter slug={id}>
			{pageData.metadata && (
				<_Head
					title={pageData.metadata[0].title + " - Pure Pet Food UK"}
					metaDescription={pageData.metadata[0].description as string}
					metaOGTitle={pageData.metadata[0].title as string}
					metaOGDescription={pageData.metadata[0].description as string}
					metaRobots={pageData.showInSearch === false || false}
				>
					{landingPageString && (
						<script
							type="application/ld+json"
							dangerouslySetInnerHTML={{
								__html: landingPageString,
							}}
						/>
					)}
				</_Head>
			)}

			{pageDataSections}
		</Layout>
	);
};
// Nextjs
// eslint-disable-next-line import/no-default-export
export default Page;
