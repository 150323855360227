import { LegacyRef, useCallback } from "react";

import { Image, ResponsiveImageType } from "react-datocms";
import c from "classnames";

import { PriceAndDiscount } from "@/components/FormElements/PriceAndDiscount";
import { Voucher } from "@/types/pet";
import { QuantitySelector } from "@/components/FormElements/QuantitySelector";
import { Button } from "@/components/FormElements/Button";
import { ButtonVariants } from "@/types/inputFields";

import Styles from "./treatsCard.module.scss";

export type TreatsCardProps = {
	onClick?: () => void;
	image: ResponsiveImageType;
	header: string;
	size: string;
	showFrom?: boolean;
	price: number;
	oldPrice?: number;
	forwardRef?: LegacyRef<HTMLDivElement>;
	fixedWidth?: boolean;
	largeMobile?: boolean;
	voucherInfo?: Voucher | null;
	subtitle?: string;
	sixOnDesktop?: boolean;
	quantity?: number;
	fourInARow?: boolean;
	slug: string;

	button?: {
		text: string;
		onClick: (quantity: number) => void;
		disabled?: boolean;
		isLoading?: boolean;
	};
};

export const TreatsCard = ({
	onClick,
	image,
	header,
	showFrom,
	oldPrice,
	price,
	size,
	subtitle,
	fixedWidth,
	largeMobile = false,
	sixOnDesktop = false,
	forwardRef,
	voucherInfo,
	button,
	quantity,
	fourInARow,
}: TreatsCardProps) => {
	const stopPropagation = useCallback((e: any) => {
		e.stopPropagation();
		e.preventDefault();
	}, []);

	return (
		<div
			className={c(Styles.card, {
				[Styles.cardHasEvent]: !!onClick,
				[Styles.fixedWidth]: fixedWidth,
				[Styles.largeMobile]: largeMobile,
				[Styles.sixOnDesktop]: sixOnDesktop,
				[Styles.fourInARow]: fourInARow,
			})}
			onClick={onClick}
			ref={forwardRef}
		>
			<div className={Styles.image}>
				<Image data={image} />
			</div>

			<div className={Styles.content}>
				<h4 className={Styles.header}>{header}</h4>

				{subtitle && <p className={Styles.subtitle}>{subtitle}</p>}

				<p className={Styles.unit}>{size}</p>

				<p className={Styles.price}>
					{showFrom && <span className={Styles.from}>from</span>}{" "}

					<PriceAndDiscount
						className={Styles.priceComponent}
						price={price}
						extras
						originalPrice={oldPrice}
						voucherTag={false}
						voucher={voucherInfo}
						color="normal"
					/>
				</p>

				{button && (
					<div onClick={stopPropagation}>
						{quantity && quantity > 0
							? (
								<>
									<QuantitySelector
										isLoadingQuantity={button.isLoading}
										quantity={quantity}
										setQuantity={button.onClick}
									/>
								</>
							)
							: (
								<>
									<Button
										type="button"
										onClick={() => button.onClick(1)}
										isWorking={button.isLoading}
										variant={ButtonVariants.clearLight}
										disabled={button.disabled}
									>
										{button.text}
									</Button>
								</>
							)}
					</div>
				)}
			</div>
		</div>
	);
};
