import c from "classnames";

import Styles from "./treatsCardContainer.module.scss";

type Props = {
	children: React.ReactNode;
	wide?: boolean;
	classname?: string;
};

export const TreatsCardContainer = ({
	children,
	wide = false,
	classname,
}: Props) => {
	return (
		<div
			className={c(Styles.wrapper, "container containerNoPad", {
				[Styles.wide]: wide,
				[`${classname}`]: !!classname,
			})}
			id="treatsWrapper"
		>
			{children}
		</div>
	);
};
