import { useCallback } from "react";

import c from "classnames";
import slugify from "react-slugify";
import { useRouter } from "next/router";

import { ProductsSection } from "@/types/page";
import { SplitHeading } from "@/components/FormElements/SplitHeading";
import { useRecipeAPIData } from "@/hooks/useRecipeAPIData";
import { TreatsCard } from "@/components/New/Treats/TreatsCard";
import { LocalProduct } from "@/types/Treats";
import { TreatsCardContainer } from "@/components/New/Treats/TreatsCardContainer";

import Styles from "./extras.module.scss";

type Props = {
	content: ProductsSection;
};

export const Extras = ({ content }: Props) => {
	const { extras } = useRecipeAPIData();

	const router = useRouter();

	const options = extras.all.filter((extra: LocalProduct) =>
		content?.products?.map((product) => product.handle).includes(extra.handle)
	);

	const onCardClick = useCallback(
		(slug: string) => {
			router.push(`/recipes/${slug}`);
		},
		[router]
	);

	return (
		<section
			id={content.id}
			className={c("sectionSpacing", Styles.products, {
				[Styles["border-" + slugify(content.border)]]: content.border,
				[Styles["sideIcons-" + slugify(content.sideIcons)]]: content.sideIcons,
			})}
		>
			{(content?.heading?.[0]?.headingLine1 || content?.content) && (
				<div className={c("container", "text-center")}>
					{content?.heading?.[0]?.headingLine1 && (
						<>
							{content.heading[0].split ? (
								<SplitHeading
									heirarchy={content.heading[0].sizing}
									line1={content.heading[0].headingLine1}
									line2={content.heading[0].headingLine2}
									colour={content.heading[0].secondaryLineColour}
								/>
							) : (
								<h2 className={c({ "no-bottom": content?.content })}>
									{content.heading[0].headingLine1}
								</h2>
							)}
						</>
					)}
					{content?.content && (
						<span
							className={c(
								{ paraLarge: content.heading?.[0]?.headingSize !== "small" },
								{ paraMed: content.heading?.[0]?.headingSize === "small" },
								Styles.contentText
							)}
							dangerouslySetInnerHTML={{ __html: content.content }}
						/>
					)}
				</div>
			)}
			<div className="container">
				<TreatsCardContainer wide>
					{options?.map(
						({
							title,
							images,
							priceStartsFrom,
							oldPriceStartsFrom,
							totalVariantsAvailable,
							variants,
							slug,
							subTitle,
						}) => (
							<TreatsCard
								oldPrice={oldPriceStartsFrom}
								onClick={() => onCardClick(slug)}
								key={title}
								header={title}
								image={images?.[0]}
								price={priceStartsFrom}
								showFrom={totalVariantsAvailable > 1}
								subtitle={subTitle}
								sixOnDesktop
								size={
									totalVariantsAvailable > 1
										? `${totalVariantsAvailable} sizes`
										: variants[0]?.size
								}
							/>
						)
					)}
				</TreatsCardContainer>
			</div>
		</section>
	);
};
